import React, { useContext, useMemo } from 'react'
import config from 'constants/config'
import {
  HOOLAH_BP_PAYMENT_TYPE,
  ATOME_BP_PAYMENT_TYPE,
  RAZORPAY_PAYMENT_TYPE,
  AFTER_PAY_BP_PAYMENT_TYPE,
  KLARNA_BP_PAYMENT_TYPE,
  WAAVE_BP_PAYMENT_TYPE,
  STRIPE_PAYMENT_TYPE,
  STRIPE_3DS_PAYMENT_TYPE,
  GOOGLE_PAY_PAYMENT_TYPE,
  APPLE_PAY_PAYMENT_TYPE,
} from 'constants/payment'
import { isKlarnaEnabled, isBraintreeEnabled, isPayPalEnabled } from 'lib/payment/paymentUtils'
import BodyText from 'components/Luxkit/Typography/BodyText'
import getPaymentMethods from 'lib/payment/getPaymentMethods'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Group from 'components/utils/Group'
import CurrencyContext from 'contexts/currencyContext'
import Image from 'components/Common/Image'
import Clickable from 'components/Common/Clickable'
import { useAppSelector } from 'hooks/reduxHooks'

type PaymentMethodContent = Array<{
  type: string;
  to?: string;
  imageId: string;
  enabled: boolean;
  height?: number;
  width?: number;
}>

function PaymentMethods() {
  const currency = useContext(CurrencyContext)
  const paymentMethods = getPaymentMethods(currency)

  const razorPayEnabled = paymentMethods.includes(RAZORPAY_PAYMENT_TYPE)

  const paypalEnabled = useAppSelector(state => isPayPalEnabled(state, currency, state.auth.account.isSpoofed))
  const braintreeEnabled = useAppSelector(state => isBraintreeEnabled(state, currency, state.auth.account.isSpoofed))

  const enabledPaymentMethods = useMemo(() => {
    const klarnaEnabled = isKlarnaEnabled(currency)
    const afterPayBpEnabled = config.AFTER_PAY_BP_ENABLED && paymentMethods.includes(AFTER_PAY_BP_PAYMENT_TYPE)
    const hoolahBpEnabled = config.HOOLAH_BP_ENABLED && paymentMethods.includes(HOOLAH_BP_PAYMENT_TYPE)
    const atomeBpEnabled = config.ATOME_BP_ENABLED && paymentMethods.includes(ATOME_BP_PAYMENT_TYPE)
    const klarnaBpEnabled = config.KLARNA_BP_ENABLED && paymentMethods.includes(KLARNA_BP_PAYMENT_TYPE)
    const waaveBpEnabled = config.WAAVE_BP_ENABLED && paymentMethods.includes(WAAVE_BP_PAYMENT_TYPE)
    const depositEnabled = config.DEPOSITS_ENABLED && (paymentMethods.includes(STRIPE_PAYMENT_TYPE) || paymentMethods.includes(STRIPE_3DS_PAYMENT_TYPE))
    const googlePayEnabled = config.GOOGLE_PAY_ENABLED && (paymentMethods.includes(GOOGLE_PAY_PAYMENT_TYPE))
    const applePayEnabled = config.APPLE_PAY_ENABLED && (paymentMethods.includes(APPLE_PAY_PAYMENT_TYPE))

    const paymentMethodsContent: PaymentMethodContent = [
      { type: 'Visa', imageId: 'payments-visa', enabled: true },
      { type: 'Mastercard', imageId: 'payments-mastercard', enabled: true },
      { type: 'Amex', imageId: 'payments-amex', enabled: true },
      { type: 'Maestro', imageId: 'payments-maestro', enabled: true },
      { type: 'PayPal', imageId: 'payments-paypal', enabled: braintreeEnabled }, // deprecated
      { type: 'PayPal', imageId: 'payments-paypal', enabled: paypalEnabled },
      { type: 'Apple Pay', imageId: 'payments-applepay', enabled: applePayEnabled },
      { type: 'Google Pay', imageId: 'payments-googlepay', enabled: googlePayEnabled },
      { type: 'Klarna Pay', imageId: 'payments-klarnapay', enabled: !!(klarnaEnabled || klarnaBpEnabled), to: '/klarna' },
      { type: 'After Pay', imageId: 'payments-afterpay', enabled: afterPayBpEnabled, to: '/afterpay' },
      { type: 'Shopback', imageId: 'payments-shopback', enabled: hoolahBpEnabled, to: '/shopbackpaylater' },
      { type: 'Atome', imageId: 'payments-atome', enabled: atomeBpEnabled },
      { type: 'LuxPay', imageId: 'payments-luxpay', enabled: waaveBpEnabled },
      { type: 'LE Deposits', imageId: 'payments-ledeposits', enabled: depositEnabled, height: 15, width: 100 },
    ]

    return paymentMethodsContent.filter(paymentMethod => paymentMethod.enabled)
  }, [currency, paymentMethods, paypalEnabled, braintreeEnabled])

  return (
    <VerticalSpacer gap={16}>
      <Group direction="horizontal" gap={8} horizontalAlign="center" tabletHorizontalAlign="end" wrap="wrap">
        {enabledPaymentMethods.map(paymentMethod => (
          <Clickable to={paymentMethod.to} key={paymentMethod.type}>
            <Image
              id={paymentMethod.imageId}
              alt={paymentMethod.type}
              height={paymentMethod.height ?? 24 }
              width={paymentMethod.width ?? 34}
            />
          </Clickable>
        ))}
      </Group>
      {razorPayEnabled && <BodyText tabletAlign="end" align="center" variant="medium" colour="neutral-two">
        Option to pay with Netbanking
      </BodyText>}
    </VerticalSpacer>
  )
}

export default React.memo(PaymentMethods)
