import React from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import TopSection from './TopSection'
import BottomSection from './BottomSection'
import TopDestinationList from 'components/Common/TopDestinationsList/TopDestinationList'
import { Route } from 'react-router-dom'
import config from 'constants/config'
import BusinessTravellerFooter from './businesstraveller/BusinessTravellerFooter'
import TrustIndicatorSection from 'home/components/TrustIndicatorSection/TrustIndicatorSection'
import Divider from 'components/Luxkit/Divider'
import { rem } from 'polished'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import LayoutContainer from 'components/Common/LayoutContainer'
import Pane from 'components/Common/Pane'
import AgentHubFooter from './agentHub/AgentHubFooter'

const Container = styled.footer`
  @media print {
    a[href]:after {
      content: "";
    }
  }
`

const StyledLayoutContainer = styled(LayoutContainer)`
  padding-top: ${rem(40)};
`

const TopSectionWrapper = styled(TopSection)`
  padding-bottom: ${rem(44)};
  padding-top: ${rem(44)};
`

function Footer() {
  const [footerRef, footerInView] = useInView({
    triggerOnce: true, // Load it once and keep it loaded
    rootMargin: '500px', // Trigger 500px before the component is reached
  })

  const showBusinessTravellerFooter = config.businessTraveller.currentAccountMode === 'business'
  const showAgentHubFooter = config.agentHub.isEnabled

  return (
    <Container className="PageFooter" ref={footerRef}>
      <Pane type="light">
        {showAgentHubFooter && <AgentHubFooter />}
        {showBusinessTravellerFooter && <BusinessTravellerFooter />}
        {!showBusinessTravellerFooter && !showAgentHubFooter && (
          <>
            <Pane>
              <Route exact path="/:regionCode" component={TopDestinationList} />
              {footerInView && <StyledLayoutContainer>
                <VerticalSpacer gap={40}>
                  <Divider kind="primary" />
                  <TrustIndicatorSection />
                  <Divider kind="primary" />
                </VerticalSpacer>
                <TopSectionWrapper />
              </StyledLayoutContainer>
              }
            </Pane>
            {footerInView && <BottomSection />}
          </>
        )}
      </Pane>
    </Container>
  )
}

export default Footer
